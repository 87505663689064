<template>
  <section class="l-content">
    <el-card v-if="dataList">
      <l-table :option="tableOption" :data="dataList"> </l-table>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarGroupList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '分组名称', prop: 'groupName' }
        ],
        operation: {
          width: '260',
          header: { type: 'warning', icon: 'el-icon-plus', label: '新增', click: this.add },
          buttons: [
            { type: 'primary', icon: 'el-icon-edit', label: '修改', click: this.edit },
            { type: 'danger', icon: 'el-icon-delete', label: '删除', click: this.del }
          ]
        }
      },
      dataList: null
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.dataList = await this.$lgh.get('api/enterprise/ecar/group/list', ['enterpriseCode', this.condition])
    },
    add() {
      this.$lgh.msgPrompt('输入分组名称', p => {
        this.$lgh
          .post('api/enterprise/ecar/group/addoredit', {
            enterpriseCode: this.userInfo.enterpriseCode,
            groupName: p
          })
          .then(res => {
            this.$lgh.toastSuccess('新增成功')
            this.getDataList()
          })
      })
    },
    edit(item) {
      this.$lgh.msgPrompt(
        '输入分组名称',
        p => {
          this.$lgh
            .post('api/enterprise/ecar/group/addoredit', {
              groupId: item.groupId,
              enterpriseCode: this.userInfo.enterpriseCode,
              groupName: p
            })
            .then(res => {
              this.$lgh.toastSuccess('修改成功')
              this.getDataList()
            })
        },
        {
          inputValue: item.groupName
        }
      )
    },
    del(item) {
      this.$lgh.msgConfirm('确认删除该分组?', p => {
        this.$lgh
          .get('api/enterprise/ecar/group/delete', {
            groupId: item.groupId,
            enterpriseCode: item.enterpriseCode
          })
          .then(res => {
            this.$lgh.toastSuccess('删除成功')
            this.getDataList()
          })
      })
    }
  }
}
</script>
